import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AVALIABLE_LANGUAGES } from './constants/i18n.constants';
import { CommonService } from './services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'frontend';

  constructor(
    private translateService: TranslateService,
    private commonService: CommonService
  ) {
    translateService.addLangs(['en', 'th']);
    translateService.setDefaultLang('en');
    let currentLanguage = commonService.getCurrentLanguage();
    if (!currentLanguage) {
      currentLanguage = AVALIABLE_LANGUAGES[0];
      commonService.saveCurrentLanguage(currentLanguage);
    }
    translateService.use(currentLanguage.short);
  }
}
